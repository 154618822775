import React, { useState, useEffect } from "react";
import {
  SearchOutlined,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
} from "@ant-design/icons";
import {
  Input,
  Button,
  Space,
  Skeleton,
  Table,
  Col,
  Drawer,
  Divider,
  Card,
} from "antd";

import { useLazyQuery } from "@apollo/client";
import { releaseOrdersQuery } from "../../queries/releaseOrdesQueries";
import { ConfigurationMoreSettings } from "../../../ConfigurationMoreSettings";
import localStorageService from "../../../../../services/localStorageService";

const companyId = localStorageService.get("companyId");

const role = localStorageService.get("role");

export const ReleaseOrders = (props) => {
  const [getReleaseOrdersQuery, dataReleaseOrdersQuery] = useLazyQuery(
    releaseOrdersQuery,
    { fetchPolicy: "no-cache" }
  );
  const [load, setLoads] = useState();
  const [showDrawer, setShowDrawer] = useState(false);
  const [dataLines, setDataLines] = useState();
  const [dataColumns, setDataColumns] = useState();
  const [filter, setFilter] = useState("");

  useEffect(() => {
    getReleaseOrdersQuery();
  }, []);

  useEffect(() => {
    if (
      dataReleaseOrdersQuery &&
      dataReleaseOrdersQuery.data &&
      dataReleaseOrdersQuery.data.historicalDeleteOrders.length > 0
    ) {
      const data = dataReleaseOrdersQuery.data.historicalDeleteOrders;
      const _dataReleaseOrdersQuery = data.map((item) => {
        return {
          ...item,
          key: item.id,
          orderNumber: item.DeleteOrdersTable.orderNumber,
          consecutiveBurden: item.DeleteOrdersTable.consecutiveBurden,
        };
      });
      setLoads(_dataReleaseOrdersQuery);
    }
  }, [dataReleaseOrdersQuery, dataReleaseOrdersQuery.data]);
  useEffect(() => {
    setFilter(
      localStorage.getItem("searchTarget") &&
        localStorage.getItem("searchTarget").length > 0
        ? localStorage.getItem("searchTarget")
        : ""
    );
    console.log(localStorage.getItem("searchTarget"));
  }, [props.flag]);

  const filteredData =
    filter.length > 0
      ? load.filter(
          (item) =>
            item.orderNumber.toUpperCase().includes(filter.toUpperCase()) ||
            item.name.toUpperCase().includes(filter.toUpperCase()) ||
            item.type.toUpperCase().includes(filter.toUpperCase()) ||
            item.consecutiveBurden?.toUpperCase().includes(filter.toUpperCase())
        )
      : load;

  const handleNew = (data) => {
    console.log(data.DeleteOrdersTable.DeleteOrdersLine);
    setShowDrawer(true);
    setDataLines(data.DeleteOrdersTable.DeleteOrdersLine);
    setDataColumns(data.DeleteOrdersTable);
  };
  const handleClear = () => {
    setShowDrawer(false);
  };

  const columns = [
    {
      title: "Consecutivo UM",
      key: "orderNumber",
      dataIndex: "DeleteOrdersTable",
      align: "center",
      render: (record) => {
        return <span>{record.orderNumber}</span>;
      },
      sorter: (a, b) => a.orderNumber.localeCompare(b.orderNumber),
    },
    {
      title: "Numero de Carga",
      key: "consecutiveBurden",
      dataIndex: "DeleteOrdersTable",
      align: "center",
      render: (record) => {
        return <span>{record.consecutiveBurden}</span>;
      },
      sorter: (a, b) => a.consecutiveBurden.localeCompare(b.consecutiveBurden),
    },
    {
      title: "Planificador",
      key: "displayName",
      dataIndex: "User",
      align: "center",
      render: (record) => {
        return <span>{record.displayName}</span>;
      },
      sorter: (a, b) => a.displayName.localeCompare(b.displayName),
    },
    {
      title: "Nombre de quien libera la orden",
      key: "name",
      dataIndex: "name",
      align: "center",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Documento de quien libera la orden",
      key: "identificationNumber",
      dataIndex: "identificationNumber",
      align: "center",
      sorter: (a, b) =>
        a.identificationNumber.localeCompare(b.identificationNumber),
    },
    {
      title: "Estado",
      key: "type",
      dataIndex: "type",
      align: "center",
      sorter: {
        compare: (a, b) => a.type - b.type,
      },
    },
    {
      title: "Justificación",
      key: "coment",
      dataIndex: "coment",
      align: "center",
      sorter: {
        compare: (a, b) => a.coment - b.coment,
      },
    },
    {
      key: "actions",
      dataIndex: "actions",
      title: "Lineas",
      align: "center",
      render: (_value, record, _index) => {
        return (
          <div>
            <Button
              type="primary"
              shape="round"
              icon={
                record.lineError ? (
                  <CloseCircleTwoTone
                    twoToneColor="red"
                    style={{ fontSize: "1.5em" }}
                  />
                ) : (
                  <CheckCircleTwoTone
                    twoToneColor="#52c41a"
                    style={{ fontSize: "1.5em" }}
                  />
                )
              }
              onClick={() => {
                handleNew(record);
              }}
            >
              Mostrar
            </Button>
          </div>
        );
      },
    },
  ];
  const columnsLines = [
    {
      title: "Consecutivo UM",
      key: "orderNumber",
      dataIndex: "orderNumber",
      align: "center",
    },
    {
      title: "Numero del Producto",
      key: "productNumber",
      dataIndex: "productNumber",
      align: "center",
    },
    {
      title: "Nombre del Producto",
      key: "productName",
      dataIndex: "productName",
      align: "center",
    },
    {
      title: "Cantidad",
      key: "orderedQuantity",
      dataIndex: "orderedQuantity",
      align: "center",
    },
  ];
  return (
    <Col className='children-inner-container-with-bg'>
      <ConfigurationMoreSettings />
      <Skeleton loading={""} active>
        <Table
          columns={columns}
          dataSource={filteredData}
          className="children-table-releaseOrders"
          size="small"
          rowKey="id"
          pagination={{ pageSize: 20 }}
          onRow={(record) => {}}
        />
      </Skeleton>
      <div
        style={{
          margin: "10px",
        }}
      >
        <Drawer
          key="drawer"
          title={`Lineas Liberadas de la orden ${dataColumns?.orderNumber}`}
          width={600}
          onClose={() => {
            handleClear();
          }}
          visible={showDrawer}
          bodyStyle={{ paddingBottom: 80 }}
        >
          <Card
            key="card"
            title={``}
            style={{ marginBottom: "10px", minHeight: "0%" }}
            size="small"
          >
            <Table
              columns={columnsLines}
              dataSource={dataLines}
              width={580}
              size="small"
              rowKey="id"
              pagination={{ pageSize: 13 }}
              onRow={(record) => {}}
            />
          </Card>
        </Drawer>
      </div>
    </Col>
  );
};
