import React, { useState, useEffect } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import  { Row, Col }  from 'antd';


import truck from '../../../../images/truck.png';
import truckWeight from '../../../../images/truck-weight.webp';

export const DriverDetails = (props) => {
    const { selectedUserVehicle } = props;
    const [userData, setUserData] = useState({});
    
    useEffect(() => {
        setUserData({});

        if(selectedUserVehicle.length === 1) {
            let orderWeight = 0;
            let toSum = 0;

            selectedUserVehicle[0].assignedRoutes.map((item) => {
                if(item.status === 'none' || item.status === 'start' || item.status === 'started' || item.status === 'undelivered') {
                    toSum = toSum + item.orderTable.weight;
                }
                return orderWeight = toSum;
            });
            setUserData(selectedUserVehicle[0]);
        }
        
    }, [selectedUserVehicle]);

    return (
        <Col span={24} className="driver-details-container">

            <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Sede</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{userData.headquarters}</p></Col>
            </Row>
            <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Placa</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{userData.licencePlate}</p></Col>
            </Row>
            <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Tipo de Vehículo</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{userData.vehicle && userData.vehicle.vehicleType && userData.vehicle.vehicleType.name}</p></Col>
            </Row>
            <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Nombre Conductor</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{userData.displayName}</p></Col>
            </Row>
            <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Teléfono</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{userData.phoneNumber}</p></Col>
            </Row>
            <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Última Actualización</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{moment(selectedUserVehicle[0] && selectedUserVehicle[0].lastLocationUpdatedAt ? selectedUserVehicle[0].lastLocationUpdatedAt : null).format("DD/MM/YYYY hh:mm A")}</p></Col>
            </Row>
            {/* <Row gutter={[0, 0]} className="driver-details-item">
                <Col className="driver-details-item-label" span={12}><p>Fecha de Creación</p></Col>
                <Col className="driver-details-item-value" span={12}><p>{moment(selectedUserVehicle[0] && selectedUserVehicle[0].createdAt ? selectedUserVehicle[0].createdAt : null).format("DD/MM/YYYY hh:mm A")}</p></Col>
            </Row> */}
        </Col>
    );
}