import { gql } from "@apollo/client";

export const fetchUsersVehicles = gql`
query fetchUsersVehicles($distributionCenterId: [uuid!]) {
  userVehicle(
    where: {
      userDistributionCentersId: { distributionCenterId: { _in: $distributionCenterId } }
      _and: { hasAssignedRoute: { _eq: true } }
    }
  ) {
    id
    createdAt
    lastLocation
    updatedAt
    userId
    hasAssignedRoute
    user {
      identificationNumber
      displayName
      phoneNumber
      distributionCenterId
    }
    vehicleId
    vehicle {
      licencePlate
      height
      width
      depth
      tare
      weight
      schedulingStartLocation
      vehicleType {
        name
      }
    }
    assignedRoutes(
      order_by: { routeOrder: asc }
      where: { routeBatch: { endDateRoute: { _is_null: true } } }
    ) {
      id
      routeOrder
      status
      updatedAt
      routeBatch {
        id
        batch
        distanceText
        distanceValue
        durationText
        durationValue
        estimatedEndTime
        polylines
        createdAt
      }
      orderTableId
      orderTable {
        orderNumber
        address
        addressComplement
        custFullName
        custIdentificationNumber
        custPhoneNumber
        destination
        weight
        consecutiveBill
        consecutiveRemission
        consecutiveSaleOrder
        consecutiveShipping
        consecutiveBurden
      }
    }
    assignedRoutes_aggregate(
      where: { routeBatch: { endDateRoute: { _is_null: true } } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
}
`;

export const hasAssignedRouteFalse = gql`
query hasAssignedRouteFalse($distributionCenterId: [uuid!]) {
  userVehicle(where: {
    userDistributionCentersId: { distributionCenterId: { _in: $distributionCenterId } }
    _and: { hasAssignedRoute: { _eq: false } }
  }) {
    id
    createdAt
    lastLocation
    updatedAt
    userId
    hasAssignedRoute
    user {
      identificationNumber
      displayName
      phoneNumber
    }
    vehicleId
    vehicle {
      licencePlate
      height
      width
      depth
      tare
      weight
      schedulingStartLocation
      vehicleType {
        name
      }
    }
    assignedRoutes(
      order_by: { routeOrder: asc }
      where: { routeBatch: { endDateRoute: { _is_null: true } } }
    ) {
      id
      routeOrder
      status
      updatedAt
      routeBatch {
        id
        batch
        distanceText
        distanceValue
        durationText
        durationValue
        estimatedEndTime
        polylines
        createdAt
      }
      orderTableId
      orderTable {
        orderNumber
        address
        addressComplement
        custFullName
        custIdentificationNumber
        custPhoneNumber
        destination
        weight
      }
    }
    assignedRoutes_aggregate(
      where: { routeBatch: { endDateRoute: { _is_null: true } } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
}
`;

export const hasAssignedRouteTrue = gql`
query hasAssignedRouteTrue($userId: uuid!, $distributionCenterId: [uuid!]) {
  userVehicle(
    where: { hasAssignedRoute: { _eq: true }, userId: { _neq: $userId }, _and:{userDistributionCentersId: { distributionCenterId: { _in: $distributionCenterId }}}}
  ) {
    id
    createdAt
    lastLocation
    updatedAt
    userId
    hasAssignedRoute
    user {
      identificationNumber
      displayName
      phoneNumber
    }
    vehicleId
    vehicle {
      licencePlate
      height
      width
      depth
      tare
      weight
      schedulingStartLocation
      vehicleType {
        name
      }
    }
    assignedRoutes(
      order_by: { routeOrder: asc }
      where: { routeBatch: { endDateRoute: { _is_null: true } } }
    ) {
      id
      routeOrder
      status
      updatedAt
      routeBatch {
        id
        batch
        distanceText
        distanceValue
        durationText
        durationValue
        estimatedEndTime
        polylines
        createdAt
      }
      orderTableId
      orderTable {
        orderNumber
        address
        addressComplement
        custFullName
        custIdentificationNumber
        custPhoneNumber
        destination
        weight
      }
    }
    assignedRoutes_aggregate(
      where: { routeBatch: { endDateRoute: { _is_null: true } } }
    ) {
      aggregate {
        count(columns: id)
      }
    }
  }
}
`;
export const queryHasAssignedRoute = gql`
  query queryHasAssignedRoute($userId: uuid) {
    userVehicle(where: { userId: { _eq: $userId } }) {
      id
      userId
      user {
        displayName
      }
      assignedRoutes(
        order_by: { routeOrder: asc }
        where: { routeBatch: { endDateRoute: { _is_null: true } } }
      ) {
        id
      }
    }
  }
`;
export const updateMoveRoute = gql`
  mutation ($id: uuid, $userId: uuid, $batch: uuid, $routeOrder: Int) {
    update_routes(
      where: { id: { _eq: $id } }
      _set: {
        userId: $userId
        batch: $batch
        routeOrder: $routeOrder
        polylines: ""
        durationText: ""
        distanceText: ""
        durationValue: 0
        distanceValue: 0
      }
    ) {
      affected_rows
    }
  }
`;
export const updateUserIdRoute = gql`
mutation ($id: uuid, $userId: uuid, $licencePlate: String) {
  update_routesBatch(
    where: { id: { _eq: $id } }
    _set: {
      userId: $userId,
      licencePlate : $licencePlate
    }
  ) {
    affected_rows
  }
}
`;
export const updateMoveRouteComplet = gql`
  mutation ($id: uuid, $userId: uuid, $batch: uuid, $routeOrder: Int) {
    update_routes(
      where: { id: { _eq: $id } }
      _set: {
        userId: $userId
        batch: $batch
        routeOrder: $routeOrder
        polylines: ""
        durationText: ""
        distanceText: ""
        durationValue: 0
        distanceValue: 0
      }
    ) {
      affected_rows
    }
  }
`;
export const updateHasAssignedRoute = gql`
  mutation ($userId: uuid, $hasAssignedRoute: Boolean) {
    update_userVehicle(
      where: { userId: { _eq: $userId } }
      _set: { hasAssignedRoute: $hasAssignedRoute }
    ) {
      affected_rows
    }
  }
`;

export const updateHasAssignedRouteCompleted = gql`
mutation ($userId: uuid, $hasAssignedRoute: Boolean) {
  update_userVehicle(
    where: { userId: { _eq: $userId } }
    _set: { hasAssignedRoute: $hasAssignedRoute }
  ) {
    affected_rows
  }
}
`;
export const updateHasAssignedRouteCompletedTrue = gql`
mutation ($userId: uuid, $hasAssignedRoute: Boolean) {
  update_userVehicle(
    where: { userId: { _eq: $userId } }
    _set: { hasAssignedRoute: $hasAssignedRoute }
  ) {
    affected_rows
  }
}
`;

export const updateRoutes = gql`
  mutation updateRoutes($userIdCurrent: uuid!, $userIdNew: uuid!) {
    update_routes(
      _set: { userId: $userIdNew }
      where: { userId: { _eq: $userIdCurrent } }
    ) {
      affected_rows
    }
  }
`;
export const updateUserVehicle = gql`
  mutation updateuserVehicle($userIdNew: uuid!) {
    update_userVehicle(
      _set: { hasAssignedRoute: true }
      where: { userId: { _eq: $userIdNew } }
    ) {
      affected_rows
    }
  }
`;
export const subscriptionFetchUsersVehicles = gql`
  subscription subscriptionFetchUsersVehicles($distributionCenterId: [uuid!]) {
    userVehicle(
      where: {
        userDistributionCentersId: { distributionCenterId: { _in: $distributionCenterId } }
        hasAssignedRoute: { _eq: true }
      }
    ) {
      id
      createdAt
      lastLocation
      updatedAt
      userId
      hasAssignedRoute
      user {
        identificationNumber
        displayName
        phoneNumber
        distributionCenterId
        headquarters
      }
      vehicleId
      vehicle {
        licencePlate
        height
        width
        depth
        tare
        weight
        schedulingStartLocation
        vehicleType {
          name
        }
      }
      assignedRoutes(
        order_by: { routeOrder: asc }
        where: { routeBatch: { endDateRoute: { _is_null: true } } }
      ) {
        id
        routeOrder
        status
        updatedAt
        routeBatch {
          id
          batch
          distanceText
          distanceValue
          durationText
          durationValue
          estimatedEndTime
          polylines
          createdAt
        }
        orderTableId
        orderTable {
          orderNumber
          address
          addressComplement
          custFullName
          custIdentificationNumber
          custPhoneNumber
          destination
          weight
          consecutiveBill
          consecutiveRemission
          consecutiveSaleOrder
          consecutiveShipping
          consecutiveBurden
        }
      }
      assignedRoutes_aggregate(
        where: { routeBatch: { endDateRoute: { _is_null: true } } }
      ) {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

export const updateRoutesBatch = gql`
  mutation updateRoutesBatch($userIdCurrent: uuid!, $userIdNew: uuid!) {
    update_routesBatch(
      _set: { userId: $userIdNew }
      where: { userId: { _eq: $userIdCurrent } }
    ) {
      affected_rows
    }
  }
`;
export const update_UserVehicle = gql`
  mutation update_UserVehicle($userIdCurrent: uuid!) {
    update_userVehicle(
      _set: { hasAssignedRoute: false }
      where: { userId: { _eq: $userIdCurrent } }
    ) {
      affected_rows
    }
  }
`;
