import React, { useState, useEffect } from "react";
import {
  Button,
  Drawer,
  Card,
  Divider,
  Form,
  Input,
  Select,
  Checkbox,
  Switch,
} from "antd";
import Swal from "sweetalert2";
import { useLazyQuery, useMutation, useApolloClient } from "@apollo/client";
import localStorageService from "../../../../../services/localStorageService";
import axios from "axios";
import "../styles/Styles.css";
import bcrypt from "bcryptjs";
import TextField from "@mui/material/TextField";
import {
  checkIfEmailExists,
  checkIfIdentificationNumberExists,
  checkIfIdentificationNumberEmailExists,
  insertUser,
  updateUser,
  updateUserInsertAutomaticPassword,
  fetchRoles,
  updateDistributionCenters,
  cleanDistrutionCenter,
} from "../../queries/userQueries";
import "../styles/styles";
const companyId = localStorageService.get("companyId");

const UserDetailsScreen = ({ props }) => {
  const apolloClient = useApolloClient();
  const [form] = Form.useForm();

  const {
    showDrawer,
    setShowDrawer,
    recordToEdit,
    setRecordToEdit,
    isEditing,
    setIsEditing,
    users,
    setUsers,
    dataDistributionCenters,
  } = props;

  const [role, setRole] = useState("");
  const [identificationVerification, setIdentificationVerification] =
    useState(false);
  const [emailVerification, setEmailVerification] = useState(false);
  const [distributionCenters, setDistributionCenters] = useState([]);
  const [distributionCentersName, setDistributionCentersName] = useState([]);
  const [verificationemail, setVerificationemail] = useState(false);

  const [getRoles, dataRoles] = useLazyQuery(fetchRoles, {
    fetchPolicy: "no-cache",
  });
  const [insUser] = useMutation(insertUser, { fetchPolicy: "no-cache" });
  const [udpUser] = useMutation(updateUser, { fetchPolicy: "no-cache" });

  const [cleanDistCenter] = useMutation(cleanDistrutionCenter, {
    fetchPolicy: "no-cache",
  });
  const [updateDistCenter] = useMutation(updateDistributionCenters, {
    fetchPolicy: "no-cache",
  });

  const [InsertPasswordAutomaticUser] = useMutation(
    updateUserInsertAutomaticPassword,
    { fetchPolicy: "no-cache" }
  );

  useEffect(() => {
    getRoles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      id: recordToEdit.id,
      displayName: recordToEdit.displayName,
      email: recordToEdit.email,
      identificationNumber: recordToEdit.identificationNumber,
      phoneNumber: recordToEdit.phoneNumber,
      roleId: recordToEdit.roleId,
      roleName: recordToEdit.roleName,
      driverLicense: recordToEdit.driverLicense,
      photoURL: recordToEdit.photoURL ?? "",
      address: recordToEdit.address ?? "",
      enabled: recordToEdit.enabled ?? true,
      distributionCenter: recordToEdit.distributionCenter ?? "",
      distributionCenterId: recordToEdit.distributionCenterId ?? [],
      headquarters: recordToEdit.headquarters
    });

    setRole(recordToEdit.roleName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recordToEdit]);

  useEffect(() => {
    if (
      !dataDistributionCenters.loading &&
      dataDistributionCenters.data &&
      dataDistributionCenters.data.distributionCenters.length > 0
    ) {
      dataDistributionCenters.data.distributionCenters.map((item) => {
        const { id, name } = item;

        return setDistributionCenters((current) => [
          ...current,
          {
            id,
            name,
          },
        ]);
      });
    }
  }, [dataDistributionCenters.loading, dataDistributionCenters.data]);

  const formItemLayout = {
    labelCol: {
      lg: { span: 8 },
    },
    wrapperCol: {
      lg: { span: 15 },
    },
  };
  const verificationEmail = async (value) => {
    const email = value;
    apolloClient
      .query({
        query: checkIfEmailExists,
        variables: { email },
      })
      .then(({ data }) => {
        if (data.users.length > 0) {
          setEmailVerification(true);
        } else {
          setEmailVerification(false);
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: "Error",
          text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
          icon: "error",
          confirmButtonText: "Cerrar",
          willClose: () => {
            return;
          },
        });
      });
  };

  const verificationIdentification = async (value) => {
    const identificationNumber = value;
    apolloClient
      .query({
        query: checkIfIdentificationNumberExists,
        variables: { identificationNumber },
      })
      .then(({ data }) => {
        if (data.users.length > 0) {
          setIdentificationVerification(true);
        } else {
          setIdentificationVerification(false);
        }
      })
      .catch((error) => {
        console.error(error);
        Swal.fire({
          title: "Error",
          text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
          icon: "error",
          confirmButtonText: "Cerrar",
          willClose: () => {
            return;
          },
        });
      });
  };

  const renewDistributionCenters = async (id, distributionCenterId) => {
    await cleanDistCenter({ variables: { id } }).then((res) => {
    });
    const distributionCenters = distributionCenterId.map((item) => {
      return {
        userId: id,
        distributionCenterId: item,
      };
    });

    await updateDistCenter({
      variables: { distributionCenters },
    }).then((res) => {
    });
  };

  const handleSave = async () => {
    form
      .validateFields()
      .then((_) => {
        const values = form.getFieldsValue();
        const {
          id,
          displayName,
          email,
          identificationNumber,
          phoneNumber,
          roleId,
          roleName,
          driverLicense,
          address,
          photoURL,
          enabled,
          distributionCenter,
          distributionCenterId,
          hasRoutes,
          headquarters
        } = values;
        if (!isEditing) {
          insUser({
            variables: {
              displayName,
              email,
              identificationNumber,
              phoneNumber,
              roleId,
              driverLicense,
              address,
              photoURL,
              enabled,
              companyId,
              headquarters,
              distributionCenterId:form.getFieldValue("distributionCenterId")[0].toString()
            },
          })
            .then((res) => {
              if (res.data.insert_users.affected_rows > 0) {
                const { id } = res.data.insert_users.returning[0];
                renewDistributionCenters(id, distributionCenterId);
                setUsers((current) => [
                  ...current,
                  {
                    id,
                    displayName,
                    email,
                    identificationNumber,
                    phoneNumber,
                    roleId,
                    roleName,
                    driverLicense,
                    address,
                    photoURL,
                    enabled,
                    distributionCenterId,
                    headquarters,
                    distributionCenter: `${distributionCentersName} ` ?? [],
                    hasRoutes,
                  },
                ]);
                Swal.fire({
                  title: "Registro de Usuario Exitoso",
                  text: "Se Registro el Usuario Correctamente en el Sistema!",
                  icon: "success",
                  confirmButtonText: "Aceptar",
                  willClose: () => {
                    verificationemail === true
                      ? Swal.fire({
                          title: "Enviar Verificación al Correo Electronico",
                          text: "Se Enviara al Correo Electronico Registrado una Notificacion de Verificación!",
                          icon: "success",
                          confirmButtonText: "Aceptar",
                          willClose: () => {
                            const data = {
                              id,
                              email,
                              displayName,
                              roleId,
                            };

                            handleSendEmailVerification(data);
                          },
                        })
                      : Swal.fire({
                          title: "Contraseña Automatica",
                          text: "Su Contraseña es el Numero de Documento Registrado en el sistema!",
                          icon: "success",
                          confirmButtonText: "Aceptar",
                          willClose: () => {
                            const data = { id, identificationNumber, email };
                            passwordIdentificationNumber(data);
                          },
                        });
                  },
                });
              }

              handleClear();
              setShowDrawer(false);
            })
            .catch((error) => {
              console.error(error);
            });
        } else {
          apolloClient
            .query({
              query: checkIfIdentificationNumberEmailExists,
              variables: { identificationNumber, email },
            })
            .then(({ data }) => {
              if (data.users.length > 0) {
                Swal.fire({
                  title: "Error",
                  text: "El número de identificación que intenta registrar ya existe para otra cuenta, ingrese otro.",
                  icon: "error",
                  confirmButtonText: "Cerrar",
                  timer: 5000,
                  timerProgressBar: true,
                  willClose: () => {
                    return;
                  },
                });
              }
            })
            .catch((error) => {
              console.error(error);
              Swal.fire({
                title: "Error",
                text: `Ocurrió un error. Consulte al administrador. ${error.toString()}`,
                icon: "error",
                confirmButtonText: "Cerrar",
                willClose: () => {
                  return;
                },
              });
            });

          udpUser({
            variables: {
              id,
              displayName,
              email,
              identificationNumber,
              phoneNumber,
              roleId,
              driverLicense,
              address,
              photoURL,
              enabled,
              distributionCenter,
              headquarters,
            },
          })
            .then((res) => {
              if (res.data.update_users.affected_rows > 0) {
                renewDistributionCenters(id, distributionCenterId);
                const records = users;
                const indexToReplace = users.findIndex(
                  (item) => item.id === id
                );

                records.splice(indexToReplace, 1, {
                  id,
                  displayName,
                  email,
                  identificationNumber,
                  phoneNumber,
                  roleId,
                  roleName,
                  driverLicense,
                  address,
                  photoURL,
                  enabled,
                  distributionCenterId,
                  headquarters,
                  distributionCenter: `${distributionCentersName} ` ?? [],
                });
                setUsers([...records]);

                Swal.fire({
                  title: "Actualización exitosa",
                  text: "Usuario actualizado exitosamente!",
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });
              }
              handleClear();
              setShowDrawer(false);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleSendEmailVerification = (data) => {
    const companyBrand = JSON.parse(localStorageService.get("companyDetails"));

    axios({
      method: "post",
      url: process.env.REACT_APP_FUNCTION_EMAIL_VERIFICATION_URL,
      data: {
        id: data.id,
        email: data.email,
        displayName: data.displayName,
        roleId: data.roleId,
        origin: window.location.origin,
        bigBrand: companyBrand.bigBrand,
        companyName: localStorageService.get("company"),
        primaryColor: companyBrand.primaryColor,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          Swal.fire({
            title: "Enviado",
            text: `El correo electrónico de verificación fue enviado.`,
            icon: "success",
            confirmButtonText: "Cerrar",
            timer: 2000,
            timerProgressBar: true,
          });
        } else {
          Swal.fire({
            title: "Error",
            text: `No fue posible enviar el correo electrónico de verificación.`,
            icon: "error",
            confirmButtonText: "Cerrar",
            timer: 2000,
            timerProgressBar: true,
          });
        }
      })
      .catch((error) => {
        Swal.fire({
          title: "Error",
          text: `No fue posible enviar el correo electrónico de verificación. ${error.toString()}`,
          icon: "error",
          confirmButtonText: "Cerrar",
          timer: 2000,
          timerProgressBar: true,
        });
      });
  };

  const passwordIdentificationNumber = (data) => {
    const id = data.id;
    const email = data.email;
    const password = data.identificationNumber;

    bcrypt.genSalt(5, (err, salt) => {
      if (err == null) {
        bcrypt.hash(email + password, salt, (error, hash) => {
          if (error == null) {
            InsertPasswordAutomaticUser({
              variables: {
                id: id,
                password: hash,
              },
            })
              .then((res) => {
                if (res.data.update_users.affected_rows > 0) {
                  Swal.fire({
                    title: "Registro de Contraseña Exitoso",
                    text: "La Contraseña del Usuario fue Generada de Manera Correcta!",
                    icon: "success",
                    confirmButtonText: "Aceptar",
                    willClose: () => {
                      return;
                    },
                  });
                }
              })
              .catch((er) => {
                Swal.fire({
                  title: "Error",
                  text: `Ocurrió un error al intentar procesar los datos. Muestre la siguiente información al administrador: ${er.toString()}`,
                  icon: "error",
                  confirmButtonText: "Cerrar",
                });
              });
          }
        });
      }
    });
  };

  const handleClear = () => {
    setRecordToEdit({});
    setIsEditing(false);
    setRole("");

    form.setFieldsValue({
      id: "",
      displayName: "",
      email: "",
      identificationNumber: "",
      phoneNumber: "",
      roleId: undefined,
      roleName: undefined,
      driverLicense: undefined,
      address: undefined,
      photoURL: undefined,
      enabled: true,
      distributionCenterId: null,
      distributionCenter: null,
      headquarters: null
    });
  };

  return (
    <div
      style={{
        margin: "10px",
      }}
    >
      <Drawer
        key="drawer"
        title={`Usuario`}
        width={600}
        onClose={() => {
          handleClear();
          setShowDrawer(false);
        }}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              shape="round"
              onClick={() => {
                handleSave();
              }}
              disabled={
                isEditing === false
                  ? identificationVerification === true ||
                    emailVerification === true
                  : ""
              }
            >
              Guardar
            </Button>
            <Divider type="vertical" />
            <Button
              type="ghost"
              shape="round"
              onClick={() => {
                handleClear();
                setShowDrawer(false);
              }}
            >
              Cerrar
            </Button>
          </div>
        }
      >
        <Card
          key="card"
          title={`${isEditing ? "Modificar" : "Agregar"}`}
          style={{ marginBottom: "10px", minHeight: "0%" }}
          size="small"
        >
          <Form
            {...formItemLayout}
            key="form"
            form={form}
            name="configuration-users"
            onClose={() => {
              handleClear();
              setShowDrawer(false);
            }}
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="displayName"
              labelAlign="left"
              label="Nombre y apellido"
              hasFeedback
              rules={[
                { required: true, message: "" },
                ({ _ }) => ({
                  validator(_, value) {
                    const textToValidate = new RegExp(/^[a-zA-Z\s]*$/);
                    const res = textToValidate.test(value);

                    if (!res) {
                      return Promise.reject("Solo letras");
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
            >
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              name="identificationNumber"
              labelAlign="left"
              label="Nro. Identificación"
              hasFeedback
              rules={[{ required: true, message: "" }]}
            >
              <TextField
                className="textField"
                disabled={isEditing}
                onBlur={(value, object) =>
                  verificationIdentification(value.target.value)
                }
                helperText={
                  identificationVerification === true && isEditing === false ? (
                    <p className="identificationVerification">
                      El Numero de Documento Ingresado ya Existe en la Base de
                      Datos
                    </p>
                  ) : (
                    ""
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="email"
              labelAlign="left"
              label="Correo electrónico"
              hasFeedback
              rules={[{ required: true, message: "", type: "email" }]}
            >
              <TextField
                className="textField"
                disabled={isEditing}
                onBlur={(value, object) =>
                  verificationEmail(value.target.value)
                }
                helperText={
                  emailVerification === true && isEditing === false ? (
                    <p className="emailVerification">
                      El Correo Electronico Ingresado ya Existe en la Base de
                      Datos
                    </p>
                  ) : (
                    ""
                  )
                }
              />
            </Form.Item>
            <Form.Item
              name="phoneNumber"
              labelAlign="left"
              label="Nro. Celular"
              hasFeedback
              rules={[
                { required: true, message: "" },
                ({ _ }) => ({
                  validator(_, value) {
                    if (value && isNaN(value)) {
                      return Promise.reject("Solo números");
                    }
                    if (value && (value.length < 10 || value.length > 10)) {
                      return Promise.reject("Deben ser 10 dígitos");
                    }

                    return Promise.resolve();
                  },
                }),
              ]}
            >
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name="address"
              label="Dirección de residencia"
            >
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              name="roleId"
              labelAlign="left"
              label="Rol"
              hasFeedback
              rules={[{ required: true, message: "" }]}
            >
              <Select
                placeholder="Seleccione..."
                allowClear
                onChange={(value, object) => {
                  if (value !== undefined) {
                    setRole(object.children);
                    return form.setFieldsValue({
                      roleId: value,
                      roleName: object.children,
                    });
                  }
                }}
              >
                {dataRoles &&
                  dataRoles.data &&
                  dataRoles.data.roles.map((item) => {
                    return (
                      <Select.Option value={item.id}>
                        {item.label}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item labelAlign="left" name="roleName" hidden>
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              hidden={role !== "driver"}
              name="driverLicense"
              label="Nro. Licencia"
              hasFeedback
              rules={[{ required: role === "driver", message: "" }]}
            >
              <TextField className="textField" />
            </Form.Item>

            <Form.Item
              labelAlign="left"
              hasFeedback
              name="distributionCenterId"
              label="Centro de distribución"
            >
              <Select
                placeholder="Seleccione..."
                allowClear
                optionFilterProp="children"
                mode="multiple"
                style={{ width: "100%" }}
                onChange={(value, object) => {
                  if (value !== undefined) {
                    setDistributionCentersName(object.map((item)=> {return item.children}))

                    return form.setFieldsValue({
                      distributionCenterId: value,
                      distributionCenter: object.children,
                    });
                  }

                  return form.setFieldsValue({
                    distributionCenterId: null,
                    distributionCenter: "",
                  });
                }}
              >
                {distributionCenters.map((item) => {
                  return (
                    <Select.Option value={item.id}>{item.name}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item labelAlign="left" name="distributionCenter" hidden>
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              name="headquarters"
              labelAlign="left"
              label="Sede"
            >
              <Select 
                placeholder="Seleccione..."
              >
                <Select.Option value={"AGUACATALA"}>AGUACATALA</Select.Option>
                <Select.Option value={"AMERICAS"}>AMERICAS</Select.Option>
                <Select.Option value={"BARRIO TRISTE"}>BARRIO TRISTE</Select.Option>
                <Select.Option value={"CONSULTOR SRF"}>CONSULTOR SRF</Select.Option>
                <Select.Option value={"CORPORATIVO"}>CORPORATIVO</Select.Option>
                <Select.Option value={"CUCUTA"}>CUCUTA</Select.Option>
                <Select.Option value={"DUITAMA"}>DUITAMA</Select.Option>
                <Select.Option value={"GIRON"}>GIRON</Select.Option>
                <Select.Option value={"IBAGUE"}>IBAGUE</Select.Option>
                <Select.Option value={"MONTERIA"}>MONTERIA</Select.Option>
                <Select.Option value={"NEIVA"}>NEIVA</Select.Option>
                <Select.Option value={"PASTO"}>PASTO</Select.Option>
                <Select.Option value={"PEREIRA"}>PEREIRA</Select.Option>
                <Select.Option value={"SOLEDAD"}>SOLEDAD</Select.Option>
                <Select.Option value={"TINTALITO"}>TINTALITO</Select.Option>
                <Select.Option value={"VILLAVICENCIO"}>VILLAVICENCIO</Select.Option>
                <Select.Option value={"YUMBO"}>YUMBO</Select.Option>
              </Select> 
              
            </Form.Item>
            <Form.Item
              labelAlign="left"
              name="enabled"
              label="Habilitado"
              valuePropName="checked"
            >
              <Switch />
            </Form.Item>
            <Form.Item
              name="ValidarCorreo"
              labelAlign="left"
              label="Validar Correo"
              valuePropName="checked"
            >
              <Switch
                onChange={(value) => {
                  setVerificationemail(value);
                }}
              />
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  );
};

export default UserDetailsScreen;
