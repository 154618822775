import React from 'react';
import packageJson from '../../../../../package.json';
import { Button, Col, Row } from 'antd';
import { CarOutlined, IdcardOutlined, DropboxOutlined, InboxOutlined, TeamOutlined, EnvironmentOutlined, WarningOutlined, InfoOutlined, SettingOutlined, AlertOutlined, BarsOutlined, BgColorsOutlined, OrderedListOutlined,SendOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import '../styles/ConfigurationSelectorScreen.css';
import localStorageService from "../../../../services/localStorageService";

const UserRole = localStorageService.get("role");
export const ConfigurationSelectorScreen = () => {
    
    return (
        <Col className='children-inner-container-with-bg' style={{textAlign: 'center'}}>
            <Row className='children-inner-row' >
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/sectors'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><EnvironmentOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Zonas</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/incidents-config'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><InfoOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Incidentes</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/incidents-causes-config'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><WarningOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Causas</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/incident-management-config'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><SettingOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Gestiones</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
            
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/reasons'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><AlertOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Motivos</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/users'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><TeamOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Usuarios</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                    <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                <Link to='/vehicle-types'>
                    <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><BarsOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Tipos de Vehículos</p></Row>
                            </Col>
                    </Button>
                </Link>
                    </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/vehicle-brands'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><CarOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Marcas de</p><p>Vehículos</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
        
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/transport-companies'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><CarOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Transportistas</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/vehicles'>
                        <Button className="children-inner-col-button" type="primary" ghost>
                            <Col>
                                <Row><CarOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Vehículos</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/distribution-centers'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row style={{marginTop: "24px"}}><InboxOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Centros de</p> <p>distribución</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/clients'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><IdcardOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Clientes</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/packages'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><DropboxOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Paquetes</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/sequences-numbers'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row style={{marginTop: "24px"}}><OrderedListOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Secuencias</p><p>numéricas</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>

                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/company-branding'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row><BgColorsOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Diseño</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>

                <Col  xs={24} sm={6} lg={4} xl={3} className='children-inner-col-box'>
                    <Link to='/releaseOrders'>
                        <Button className="children-inner-col-button" type="primary" ghost disabled={UserRole === "user"}>
                            <Col>
                                <Row style={{marginTop: "24px"}}><SendOutlined className="children-inner-col-icon"/></Row>
                                <Row className="children-inner-col-label"><p>Ordenes</p><p>Liberadas</p></Row>
                            </Col>
                        </Button>
                    </Link>
                </Col>
                
            </Row>
            <p class="footer_version">Versión {packageJson.version}</p>
        </Col>
    );
}



                            
                            
                            
                            
                            
                            
                            
                            
                            