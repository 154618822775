import { gql } from "@apollo/client";

export const fetchOnSiteDeliveries = gql`
query fetchOnSiteDeliveries($distributionCenterId: [uuid!]) {
    onSiteDeliveries(order_by: {created_at: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}}){
      id
    status
      receivedPerson
      receivedDocument
            user {
                displayName
                identificationNumber
                phoneNumber
                userVehicle{
                    id
                }
            }
            orderTable {
                orderNumber
                address
                addressComplement
                custFullName
                custIdentificationNumber
                custPhoneNumber
                paymentMethod
                sectorId
                consecutiveBill
                consecutiveRemission
                consecutiveSaleOrder
                consecutiveBurden
                notes
                consecutiveShipping
                type
                state
                city
                expectedDeliveryStartTime
                expectedDeliveryEndTime
                sector {
                    name
                }
                totalOrderAmount
                weight
                evidences {
                    evidenceType
                    evidenceURL
                    comments
                }
                ordersLines {
                    id
                    productNumber
                    productName
                    orderedQuantity
                    deliveredQuantity
                    initialQuantity
                    summationQuantity
                  }
            }
            orderTableId
            reasonId
            reason {
                description
            }
    }
  }
`;
export const fetchCompletedRoutes = gql`
query fetchCompletedRoutes($distributionCenterId: [uuid!]) {
  routesBatch(order_by: {endDateRoute: desc}, where: {user:{userDeliveryCenters:{distributionCenterId: { _in: $distributionCenterId }}}, _and:{startDateRoute: {_is_null: false}, _and: {endDateRoute: {_is_null: false}}}}) {
      id
      batch
      createdAt
      startDateRoute
      endDateRoute
      user {
          identificationNumber
          displayName
          distributionCenterId
         userDeliveryCenters{
          distributionCenterId
        }
      }
      licencePlate
      routes(order_by: {endDateTime: desc}) {
          id
          status
          startDateTime
          endDateTime
          userId
          distanceText
          distanceValue
          durationText
          durationValue
          receivedPerson
          receivedDocument
          polylines
          user {
              displayName
              identificationNumber
              phoneNumber
              userVehicle{
                  id
              }
          }
          orderTable {
              orderNumber
              address
              addressComplement
              custFullName
              custIdentificationNumber
              custPhoneNumber
              paymentMethod
              sectorId
              consecutiveBill
              consecutiveRemission
              consecutiveSaleOrder
              consecutiveBurden
              consecutiveShipping
              type
              state
              city
              expectedDeliveryStartTime
              expectedDeliveryEndTime
              sector {
                  name
              }
              totalOrderAmount
              weight
              evidences {
                  evidenceType
                  evidenceURL
                  comments
              }
              ordersLines {
                  id
                  productNumber
                  productName
                  orderedQuantity
                  deliveredQuantity
                  initialQuantity
                  summationQuantity
                }
          }
          orderTableId
          reasonId
          reason {
              description
          }
      }
  }
}
`;

export const fetchtelemetry = gql`
query fetchtelemetry($userVehicleId: uuid, $startDateTime: timestamptz, $endDateTime: timestamptz) {
  telemetry(order_by: {timestamp: asc},where: {userVehicleId: { _eq: $userVehicleId },timestamp: {_gte: $startDateTime, _lte: $endDateTime}}) {
    id
    userVehicleId
    latitude
    longitude
    timestamp
    speed
    distance
    userVehicle {
      id
      assignedRoutes {
        id
        startDateTime
        endDateTime
        orderTable{
          id
          orderNumber
        }
      }
    }
  }
}
`;