import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Col,
  Row,
  Drawer,
  Tag,
  Spin,
  Select,
  Dropdown,
  DownOutlined,
} from "antd";
import {
  insertOrdersTable,
  insertOrdersLine,
  fetchclients,
  InsertClients,
  InsertClientAddresses,
  insertClient,
  checkClientExists,
  UpdateClients
} from "../../../screens/TMS-Dynamics/queries/TMSqueries";
import {
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  CloudUploadOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import axios from "axios";
import { TextField } from "@fluentui/react/lib/TextField";
import { useMutation, useLazyQuery } from "@apollo/client";
import Swal from "sweetalert2";
import "../stylesTMS/styles.css";
import localStorageService from "../../../../../../services/localStorageService";
import loggerCooltrack from "../../../../../../services/logger-cooltrack";
import calculateGeoEnclosure from "../../../../../../services/geo-enclosure";

const companyId = localStorageService.get("companyId");
const userId = localStorageService.get("id");
const userEmail = localStorageService.get("userEmail");

export const TMSShipmentScreen = (props) => {
  const { selecteLoad, setSelecteLoad, sectors, distributionCenters, setLoads, getTMSLoad, dataOrderRunningg,CenterUser,selectedPageSize,selectedPageId,filterr } = props;
  const [tmsshipment, setTmsshipment] = useState();
  const [tmslines, setTmslines] = useState();
  const [tmstrn, setTmstrn] = useState();
  const [tmsContainers, setTmsContainers] = useState();
  const [selectedShipment, setSelectedShipment] = useState([]);
  const [dataFilterShipment, setDataFilterShipment] = useState([]);
  const [filter, setFilter] = useState("");
  const [invoce, setInvoce] = useState("");
  const [packingSlip, setPackingSlip] = useState("");
  const [visibleDrawerLines, setVisibleDrawerLines] = useState(false);
  const [visibleDrawerContainers, setVisibleDrawerContainers] = useState(false);
  const [syncInProgress, setSyncInProgress] = useState(false);
  const [previousOrderNumberList, setPreviousOrderNumberList] = useState([]);
  const [insOrdersTable] = useMutation(insertOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [insOrdersLine] = useMutation(insertOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [insClients] = useMutation(InsertClients, {
    fetchPolicy: "no-cache",
  });
  const [updateClients] = useMutation(UpdateClients, {
    fetchPolicy: "no-cache",
  });
  const [insClientAddresses] = useMutation(InsertClientAddresses, {
    fetchPolicy: "no-cache",
  });
  const [getClients, dataClients] = useLazyQuery(fetchclients, {
    fetchPolicy: "no-cache",
  });

  const [createClientNew] = useMutation(insertClient, {
    fetchPolicy: "no-cache",
  });

  const [getCheckCliente, verficationExistClient] = useLazyQuery(checkClientExists, {
    fetchPolicy: "no-cache", 
  });
  useEffect(() => {
    console.log(selecteLoad)
    const _data = selecteLoad.LoadLines.map((item) => {
      let PaymentOnCashValue = 0;
      let PaymentOnCashValueCurrency = "";
      let PaymentMode = "";
      
      if(item.ItemList?.length > 0 && item.InvoiceList?.length > 0){
        for (let k = 0; k < item.ItemList.length; k++) {
          const dataItemList = item.ItemList[k];
          for (let j = 0; j < item.InvoiceList.length; j++) {
            const invoice = item.InvoiceList[j];
            PaymentMode = invoice.Payment;
            if(invoice.Payment === "CONTRAENTREGA" && dataItemList.InvoiceId === invoice.InvoiceId){
              PaymentOnCashValue+= invoice.InvoiceAmount;
              PaymentOnCashValueCurrency = invoice.CurrencyCode;
            }
          }
        }
      }

      return {
      ...item,
      PaymentOnCashValue,
      PaymentOnCashValueCurrency,
      PaymentMode,
      InvoiceId:
        item?.InvoiceList && item?.InvoiceList.length > 0
          ? item?.InvoiceList[0]?.InvoiceId
          : "",
      PackingSlipId:
        item?.PackingSlipList && item?.PackingSlipList.length > 0
          ? item?.PackingSlipList[0]?.PackingSlipId
          : "",
      ShipmentStatus: props.dataOrderRunning?.data?.ordersTable.some(
        (order) => {
          if (item.ShipmentId && item.ShipmentId !== "") {
            return order.consecutiveShipping === item.ShipmentId;
          }
          return false;
        }
      )
        ? "Sincronizado"
        : "Pendiente",
      key: item.OrderNum,
    }}).sort((a, b) => {
      if (a.ShipmentStatus < b.ShipmentStatus) {
        return -1;
      }
      if (a.ShipmentStatus > b.ShipmentStatus) {
        return 1;
      }
      return 0;
    });
    setTmsshipment(_data);
    setDataFilterShipment(_data);
    props.getOrderRunning();
  }, [selecteLoad]);

  const _onChangeText = () => {
    if (filter) {
      setTmsshipment(
        dataFilterShipment.filter((i) => {
          if (i.ShipmentId.toString() === filter.target.value) {
            return true;
          } else if (filter.target.value === "") {
            return true;
          } else {
            return false;
          }
        })
      );
    } else {
      setDataFilterShipment(tmsshipment);
    }
  };

  useEffect(() => {
    _onChangeText();
  }, [filter]);

  const columns = [   
{
  key: "ShipmentId",
  dataIndex: "ShipmentId",
  title: "Envío",
  sorter: (a, b) => a.ShipmentId.localeCompare(b.ShipmentId),
},
{
  key: "InventLocationIdTo",
  dataIndex: "InventLocationIdTo",
  title: "Almacen Destino",
  sorter: (a, b) =>
    a.InventLocationIdTo.localeCompare(b.InventLocationIdTo),
},
{
  key: "OrderNum",
  dataIndex: "OrderNum",
  title: "Orden/TRN",
  sorter: (a, b) => a.OrderNum.localeCompare(b.OrderNum),
},
{
  key: "InvoiceId",
  dataIndex: "InvoiceList",
  title: "Factura",
  sorter: (a, b) => a.InvoiceId.localeCompare(b.InvoiceId),
  render: (record) => {
    const uniqueInvoiceIds = new Set();
    
    (selecteLoad?.LoadLines[0]?.ItemList ?? []).forEach((item) => {
      uniqueInvoiceIds.add(item.InvoiceId);
    });

    return Array.from(uniqueInvoiceIds).map((uniqueInvoiceId) => (
      <label key={uniqueInvoiceId} value={uniqueInvoiceId}>
        {`${uniqueInvoiceId} ${" "}`}
      </label>
    ));
  }
},
{
  key: "PackingSlipId",
  dataIndex: "PackingSlipList",
  title: "Remisión",
  sorter: (a, b) => a.PackingSlipId.localeCompare(b.PackingSlipId),
  render: (record) => {
    const uniquePackingSlipIds = new Set();

    (selecteLoad?.LoadLines[0]?.ItemList ?? []).forEach((item) => {
      uniquePackingSlipIds.add(item.PackingSlipId);
    });

    return Array.from(uniquePackingSlipIds).map((uniquePackingSlipId) => (
      <label key={uniquePackingSlipId} value={uniquePackingSlipId}>
        {`${uniquePackingSlipId} ${" "}`}
      </label>
    ));
  }
},
{
  key: "CustAccount",
  dataIndex: "CustAccount",
  title: "Cuenta Cliente",
  sorter: (a, b) => a.CustAccount.localeCompare(b.CustAccount),
},
{
  key: "SalesName",
  dataIndex: "SalesName",
  title: "Nombre Cliente",
  sorter: (a, b) => a.SalesName.localeCompare(b.SalesName),
},
{
  key: "OrderAccount",
  dataIndex: "OrderAccount",
  title: "Cuenta Proveedor",
  sorter: (a, b) => a.OrderAccount.localeCompare(b.OrderAccount),
},
{
  key: "PurchName",
  dataIndex: "PurchName",
  title: "Nombre Proveedor",
  sorter: (a, b) => a.PurchName.localeCompare(b.PurchName),
},
{
  key: "State",
  dataIndex: "State",
  title: "Departamento",
  sorter: (a, b) => a.State.localeCompare(b.State),
},
{
  key: "City",
  dataIndex: "City",
  title: "Ciudad",
  sorter: (a, b) => a.City.localeCompare(b.City),
},
{
  key: "Street",
  dataIndex: "Street",
  title: "Dirección",
  sorter: (a, b) => a.Street.localeCompare(b.Street),
},
{
  key: "BuildingCompliment",
  dataIndex: "BuildingCompliment",
  title: "Complemento",
  sorter: (a, b) =>
    a.BuildingCompliment.localeCompare(b.BuildingCompliment),
},
{
  key: "ContactName",
  dataIndex: "ContactName",
  title: "Nombre Contacto",
  sorter: (a, b) => a.ContactName.localeCompare(b.ContactName),
},
{
  key: "ContactPhone",
  dataIndex: "ContactPhone",
  title: "Telefono Contacto",
  sorter: (a, b) => a.ContactPhone.localeCompare(b.ContactPhone),
},
{
  key: "ContactEmail",
  dataIndex: "ContactEmail",
  title: "Email Contacto",
  sorter: (a, b) => a.ContactEmail.localeCompare(b.ContactEmail),
},
{
  key: "PaymentMode",
  dataIndex: "PaymentMode",
  title: "Forma de Pago",
  sorter: (a, b) => a.PaymentMode.localeCompare(b.PaymentMode),
},
{
  key: "PaymentOnCashValueCurrency",
  dataIndex: "PaymentOnCashValueCurrency",
  title: "Moneda",
  sorter: (a, b) => a.PaymentOnCashValueCurrency.localeCompare(b.PaymentOnCashValueCurrency),
},
{
  key: "PaymentOnCashValue",
  dataIndex: "PaymentOnCashValue",
  title: "Valor a Pagar Contraentrega",
  sorter: (a, b) => a.PaymentOnCashValue.localeCompare(b.PaymentOnCashValue),
},
{
  key: "actions",
  dataIndex: "actions",
  title: "Lineas",
  align: "center",
  render: (_value, record, _index) => {
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          icon={
            record.lineError ? (
              <CloseCircleTwoTone
                twoToneColor="red"
                style={{ fontSize: "1.5em" }}
              />
            ) : (
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "1.5em" }}
              />
            )
          }
          onClick={() => {
            getLines(record);
            setTmstrn(record);
          }}
        >
          Mostrar
        </Button>
      </div>
    );
  },
},
{
  key: "actions",
  dataIndex: "actions",
  title: "Contenedores",
  align: "center",
  render: (_value, record, _index) => {
    return (
      <div>
        <Button
          type="primary"
          shape="round"
          icon={
            record.lineError ? (
              <CloseCircleTwoTone
                twoToneColor="red"
                style={{ fontSize: "1.5em" }}
              />
            ) : (
              <CheckCircleTwoTone
                twoToneColor="#52c41a"
                style={{ fontSize: "1.5em" }}
              />
            )
          }
          onClick={() => {
            getContainers(record);
          }}
        >
          Mostrar
        </Button>
      </div>
    );
  },
},
{
  key: "Type",
  dataIndex: "Type",
  title: "Tipo",
  sorter: (a, b) => a.Type.localeCompare(b.Type),
},
{
  key: "ShipmentStatus",
  dataIndex: "ShipmentStatus",
  title: "Estado",
  sorter: (a, b) => a.AccountNum.localeCompare(b.AccountNum),
  render: (_, { ShipmentStatus }) => (
    <Tag
      color={ShipmentStatus === "Sincronizado" ? "grey" : "orange"}
      key={ShipmentStatus}
    >
      {ShipmentStatus.toUpperCase()}
    </Tag>
  ),
},
  ];

  

  const columnsLines = [
    {
      key: "ItemId",
      dataIndex: "ItemId",
      title: "Código",
      sorter: (a, b) => a.ItemId.localeCompare(b.ItemId),
    },
    {
      key: "ProductName",
      dataIndex: "ProductName",
      title: "Descripción",
      sorter: (a, b) => a.ProductName.localeCompare(b.ProductName),
    },
    {
      key: "ProductQuantity",
      dataIndex: "ProductQuantity",
      title: "Cantidad",
      sorter: (a, b) => a.ProductQuantity.localeCompare(b.ProductQuantity),
    },
    tmstrn?.ItemList[0]?.TRN === "" || tmstrn?.ItemList[0]?.TRN === null || tmstrn?.ItemList[0]?.TRN === undefined?
    {
      key: "InvoiceId",
      dataIndex: "InvoiceId",
      title: "Factura",
      sorter: (a, b) => a.InvoiceId.localeCompare(b.InvoiceId),
    } : {
      key: "TRN",
      dataIndex: "TRN",
      title: "TRN",
      sorter: (a, b) => a.InvoiceId.localeCompare(b.InvoiceId),
    },
    {
      key: "PackingSlipId",
      dataIndex: "PackingSlipId",
      title: "Remisión",
      sorter: (a, b) => a.PackingSlipId.localeCompare(b.PackingSlipId),
    }
  ];

  const columnsContainers = [
    {
      key: "ContainerId",
      dataIndex: "ContainerId",
      title: "Contenedor",
      sorter: (a, b) => a.ContainerId.localeCompare(b.ContainerId),
    },
    {
      key: "Width",
      dataIndex: "Width",
      title: "Ancho",
      sorter: (a, b) => a.Width.localeCompare(b.Width),
    },
    {
      key: "Height",
      dataIndex: "Height",
      title: "Alto",
      sorter: (a, b) => a.Height.localeCompare(b.Height),
    },
    {
      key: "Length",
      dataIndex: "Length",
      title: "Largo",
      sorter: (a, b) => a.Length.localeCompare(b.Length),
    },
    {
      key: "Weight",
      dataIndex: "Weight",
      title: "Peso",
      sorter: (a, b) => a.Weight.localeCompare(b.Weight),
    },
    {
      key: "WeightUnit",
      dataIndex: "WeightUnit",
      title: "Unidad Peso",
      sorter: (a, b) => a.WeightUnit.localeCompare(b.WeightUnit),
    },
    {
      key: "ContainerType",
      dataIndex: "ContainerType",
      title: "Tipo Contenedor",
      sorter: (a, b) => a.ContainerType.localeCompare(b.ContainerType),
    },
  ];

  const getLines = async (record) => {
    setVisibleDrawerLines(true);
    setTmslines(record.ItemList);
  };

  const getContainers = async (record) => {
    setVisibleDrawerContainers(true);
    setTmsContainers(record.ContainerList);
  };

  const calculateTotalSizeAndVolume = (containers) => {
    let totalHeight = 0;
    let totalWidth = 0;
    let totalDepth = 0;
    let totalVolume = 0;
    let totalWeight = 0;

    containers.forEach((container) => {
      totalHeight += container.height;

      if (container.width > totalWidth) {
        totalWidth = container.width;
      }

      if (container.depth > totalDepth) {
        totalDepth = container.depth;
      }

      totalVolume += container.height * container.width * container.depth;
      totalWeight += container.weight;
    });

    return {
      totalSize: {
        height: totalHeight,
        width: totalWidth,
        depth: totalDepth,
      },
      totalVolume,
      totalWeight,
    };
  };

  const validateExistsShipment = async (shipment) => {
    const exists = props.dataOrderRunning?.data?.ordersTable.some((order) => {
      if (shipment.ShipmentId && shipment.ShipmentId !== "") {
        return order.consecutiveShipping === shipment.ShipmentId;
      }

      return false;
    });
    if (exists) {
      setSyncInProgress(false);
      Swal.fire({
        title: "Orden de venta o TRN existente",
        text: `El envio ${shipment.ShipmentId} fue sincronizado anteriormente, por esta razón fue ignorado para la sincronización`,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }

    return exists;
  };

  const handleSave = async () => {
    if(selecteLoad?.LoadLines[0]?.InvoiceList?.length === 0 && selecteLoad?.LoadLines[0]?.PackingSlipList?.length === 0)
    {
      Swal.fire({
        title: "Error de Sincronización",
        text: `El envio ${selectedShipment[0]?.ShipmentId} no puede ser sincronizado ya que no cuenta con un numero de factura y remisión`,
        icon: "warning",
        confirmButtonText: "Cerrar",
      });
    }else{
    let orders = [];
    setSyncInProgress(true);
    if (selectedShipment.length > 0) {
      let orderNumberList = "";
      for (let i = 0; i < selectedShipment.length; i++) {
        const shipment = selectedShipment[i];

        const exists = await validateExistsShipment(shipment);

        if (!exists) {
          let newAddress = "";
          const address = shipment.Street.replace(",", " ")
            .replace(/\s\s+/g, " ")
            .replace("#", "")
            .split(" ");

          address.forEach((item) => {
            newAddress += item + "+";
          });

          newAddress = newAddress.substring(0, newAddress.length - 1);

          newAddress += `+${shipment.City}+${shipment.State}+${shipment.Country}`;

          const res = await axios.get(
            `https://maps.googleapis.com/maps/api/geocode/json?key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}&components=country:CO&address=${newAddress}`
          );
          console.log(res);
          let sector = calculateGeoEnclosure(
            {
              lat: res.data.results[0].geometry.location.lat,
              lng: res.data.results[0].geometry.location.lng,
            },
            sectors
          );

          if (!sector) {
            sector = sectors.find((item) => item.name === "Importados TMS");
          }

          let weight = 0;
          let height = 0;
          let width = 0;
          let depth = 0;
          let totalSize = 0;
          let totalVolume = 0;
          let individualCubic = 0;
          let totalContainers = 0;

          if (shipment.ContainerList.length > 0) {
            const containersList = shipment.ContainerList.map((container) => {
              return {
                id: container.ContainerId,
                weight:
                  container.WeightUnit === "kg"
                    ? container.Weight ?? 0
                    : 0.453592 * (container.Weight ?? 0),
                height: container.Height ?? 0,
                width: container.Width ?? 0,
                depth: container.Length ?? 0,
              };
            });

            const containerResponse =
              calculateTotalSizeAndVolume(containersList);

            totalSize = containerResponse.totalSize;
            totalVolume = containerResponse.totalVolume;
            weight = containerResponse.totalWeight;
            height = containerResponse.totalSize.height;
            width = containerResponse.totalSize.width;
            depth = containerResponse.totalSize.depth;
            individualCubic =
              containerResponse.totalVolume / containersList.length;
            totalContainers = containersList.length;
          }

          let custIdentificationNumber;
          let custFullName;
          let custPhone = shipment.ContactPhone;
          let distributionCenter;
          switch (shipment.Type) {
            case "Envío de pedidos de transferencia":
              custIdentificationNumber = shipment.InventLocationIdTo;
              distributionCenter = distributionCenters.find(
                (center) => center.name === shipment.InventLocationIdTo
              );
              custFullName = "TRN - " + distributionCenter?.description === undefined ? "N/D" :  shipment.InventLocationIdTo;
              custPhone = distributionCenter?.phone;
              break;
            case "Pedido de ventas":
              custIdentificationNumber = shipment.CustAccount;
              custFullName = shipment.SalesName;
              break;
            default:
              custIdentificationNumber = shipment.OrderAccount;
              custFullName = shipment.PurchName;
              break;
          }

          let sequenceResponse = await axios({
            method: "POST",
            url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
            data: { companyId, sequenceTypeId: "orders" },
          });

          let sequence = sequenceResponse.data.sequence;

          if (previousOrderNumberList.includes(sequence)) {
            while (previousOrderNumberList.includes(sequence)) {
              sequenceResponse = await axios({
                method: "POST",
                url: process.env.REACT_APP_FUNCTION_GET_SEQUENCE_CODE_URL,
                data: { companyId, sequenceTypeId: "orders" },
              });
              sequence = sequenceResponse.data.sequence;
            }
          }
          for (let i = 0; i < selecteLoad?.LoadLines[0]?.PackingSlipList?.length; i++) {
            const PackingSlipList = selecteLoad?.LoadLines[0]?.PackingSlipList[i];
            setPackingSlip(PackingSlipList.PackingSlipId);
          }

          let PaymentOnCashValue = 0;

          for (let j = 0; j < selecteLoad?.LoadLines[0]?.InvoiceList?.length; j++) {
            const InvoiceList = selecteLoad?.LoadLines[0]?.InvoiceList[j];
            setInvoce(InvoiceList.InvoiceId);
            if(InvoiceList.Payment === "CONTRAENTREGA"){
              PaymentOnCashValue+= InvoiceList.InvoiceAmount;
            }
          }

          setPreviousOrderNumberList([...previousOrderNumberList, sequence]);

        const ClientsResponses = await getClients({
          variables: {
            nit: shipment.CustAccount,
          },
        })
          
          let customerSupplierId = ""
          if(shipment.CustAccount !== "" && ClientsResponses?.data?.clients?.length === 0){
            const variablesInsClient = {
              nit: shipment.CustAccount,
              name: shipment.SalesName,
              companyId: companyId,
              email: shipment.ContactEmail,
              accountNumber: shipment.CustAccount,
              documentType: "NIT",
              currency: "Pesos colombianos",
              personType: "organization",
              person: "customer",
              typePerson: "Persona jurídica"
              
            }
            const _insertClients = await insClients({
              variables: {
                objects: [variablesInsClient],
              },
            })
            
            customerSupplierId = _insertClients?.data?.insert_clients?.returning[0]?.id;
            if(_insertClients?.data?.insert_clients?.returning?.length > 0){
              
              const variablesInsClientAddresses = {
                clientId: _insertClients?.data?.insert_clients?.returning[0]?.id,
                name: "Bodega Principal",
                contactName: shipment.ContactName,
                phoneNumber: shipment.ContactPhone,
                cellPhoneNumber: shipment.ContactPhone,
                address: shipment.Street,
                state: shipment.State,
                city: shipment.City,
                addressComplement: "",
                lat:  `${res.data.results[0].geometry.location.lat}`,
                lng: `${res.data.results[0].geometry.location.lng}`,
                sectorId: sector.id,
                enabled: true,
                principal: true
              };
              await insClientAddresses({
                variables: {
                  objects: [variablesInsClientAddresses],
                },
              })
            }
          }
          if (shipment.CustAccount !== "" && 
          (ClientsResponses?.data?.clients[0]?.typePerson === "" || 
           ClientsResponses?.data?.clients[0]?.typePerson === null || 
           ClientsResponses?.data?.clients[0]?.typePerson === undefined)) {
            
              const _updateTypePerson = await updateClients({
                  variables: {
                      nit: shipment.CustAccount,
                      typePerson: "Persona jurídica"
                  }
              });
            
          }
          const requestOrder = {
            orderNumber: sequence,
            customerSupplierId: shipment.CustAccount !== "" && ClientsResponses?.data?.clients?.length === 0 ? customerSupplierId : ClientsResponses?.data?.clients[0]?.id,
            address: shipment.Street,
            city: shipment.City,
            state: shipment.State,
            addressComplement: shipment.BuildingCompliment,
            sectorId: sector.id,
            custIdentificationNumber,
            custFullName,
            custPhoneNumber: custPhone,
            type:
              shipment.Type === "Envío de pedidos de transferencia"
                ? "warehousesTransfer"
                : shipment.Type === "Pedido de compra"
                ? "pickup"
                : "delivery",
            totalOrderAmount: PaymentOnCashValue,
            destination: `${res.data.results[0].geometry.location.lat},${res.data.results[0].geometry.location.lng}`,
            weight,
            height,
            width,
            depth,
            cubicMeters: individualCubic,
            totalCubicMeters: totalVolume,
            orderedQuantity: totalContainers,
            linesDetail: true,
            notes: shipment.Type,
            paymentOrCashOnDeliveryRequired: PaymentOnCashValue > 0,
            consecutiveSaleOrder: shipment.OrderNum,
            consecutiveShipping: shipment.ShipmentId,
            createByUserId: userId,
            typePerson: "Persona jurídica",
            distributionCenterId:
              distributionCenters.find(
                (item) => item.name === selecteLoad.InventLocationId
              )?.id ?? distributionCenters[0].id,
            consecutiveBurden: shipment.LoadId,
            consecutiveRemission: (() => {
              if (!selecteLoad?.LoadLines[0]?.ItemList || selecteLoad?.LoadLines[0]?.ItemList.length === 0) {
                return "";
              }
            
              const uniquePackingSlipIds = new Set();
            
              shipment.ItemList?.forEach((item) => {
                uniquePackingSlipIds.add(item.PackingSlipId);
              }); 
              return Array.from(uniquePackingSlipIds).toString();
            })(),
            consecutiveBill: (() => {
              if (!selecteLoad?.LoadLines[0]?.ItemList || selecteLoad?.LoadLines[0]?.ItemList.length === 0) {
                return "";
              }
              const uniqueInvoiceIds = new Set();
            
              shipment.ItemList?.forEach((item) => {
                uniqueInvoiceIds.add(item.InvoiceId);
              });
            
              return Array.from(uniqueInvoiceIds).toString();
            })(),
            priority: "a_high",
            custEmail: `adavid@navitrans.com.co; lgonzalez@navitrans.com.co; ${userEmail}`,
            paymentMethod: shipment.PaymMode,
            companyId: "482777f8-95e3-4b2a-8a78-7c75aa733946",
            enablePackageDimensions: true,
            packageId: "380ed460-52a7-4e88-8c2d-42cdfa31ea87",
            loadShipConfirmUTCDateTime: selecteLoad.LoadShipConfirmUTCDateTime
          };

          const resOrder = await insOrdersTable({
            variables: {
              objects: [requestOrder],
            },
          })
          
          orders.push(resOrder);
          
            if (orders.length > 0) {
              const order =
                resOrder["data"]["insert_ordersTable"]["returning"][0];
              orderNumberList += order.orderNumber + ", ";
              const data = shipment.ItemList;
              const _dataLines = [];

              const _InvoiceList = shipment.InvoiceList;

              for (let i = 0; i < data.length; i++) {
                const line = data[i];

                let externalInvoiceId = "";

                let externalSalesId = "";

                if(_InvoiceList){
                  const invoiceRecId = _InvoiceList?.find(invoice => invoice.InvoiceId === line.InvoiceId)?.RecId;
                  if(invoiceRecId){
                    externalInvoiceId = invoiceRecId.toString();
                  }
                }

                if(shipment?.SalesRecId){
                  externalSalesId = shipment.SalesRecId?.toString();
                }

                _dataLines.push({
                  orderNumber: order.orderNumber,
                  productNumber: line.ItemId,
                  productName: line.ProductName,
                  orderedQuantity: line.ProductQuantity,
                  initialQuantity:line.ProductQuantity,
                  Invoice: line.TRN === "" || line.TRN === null || line.TRN === undefined ? line.InvoiceId : line.TRN,
                  PackingSlip: line.PackingSlipId,
                  externalId: line.RecId?.toString(),
                  externalInvoiceId,
                  externalSalesId
                });
              }

              let resLine;
              resLine = await insOrdersLine({
                variables: {
                  objects: _dataLines,
                },
              });
              setSyncInProgress(false);
              if (resLine.data.insert_ordersLine.affected_rows > 0) {
                const id = resLine.data.insert_ordersLine.returning[0].id;

                Swal.fire({
                  title: "Envios y Lineas sincronizados",
                  text: `${
                    resOrder.length > 1
                      ? "Los envios fueron sincronizados correctamente, se crearon las ordenes"
                      : "El envio fue sincronizado correctamente, se creó la orden"
                  } ${orderNumberList.substring(
                    0,
                    orderNumberList.length - 2
                  )}`,
                  icon: "success",
                  confirmButtonText: "Cerrar",
                });

                loggerCooltrack({
                  module: "DataUpload",
                  operation: "SynchronizedDataUpload",
                  target: id,
                });
              } else {
                Swal.fire({
                  title: "Error al ingresar las líneas de las órdenes",
                  text: "Ocurrió un error al ingresar las líneas de las órdenes. Por favor, intente de nuevo.",
                  icon: "error",
                  confirmButtonText: "Cerrar",
                });
              }
            } else {
              setSyncInProgress(false);
              Swal.fire({
                title: "Error",
                text: "Alguno de los envios ya fue sincronizado anteriormente.",
                icon: "warning",
                confirmButtonText: "Cerrar",
              });
            }
            try {
          } catch (error) {
            setSyncInProgress(false);
            if (
              error.message.includes(
                "duplicate key value violates unique constraint"
              )
            ) {
              Swal.fire({
                title: "Error",
                text: "Alguno de los envios ya fue sincronizado anteriormente.",
                icon: "warning",
                confirmButtonText: "Cerrar",
              });
            } else {
              Swal.fire({
                title: "Error",
                text: "Ocurrió un error al procesar las órdenes. Por favor, intente de nuevo.",
                icon: "error",
                confirmButtonText: "Cerrar",
              });
            }
          }
          props.getOrderRunning();
        }
      }
    }
    }
    setSyncInProgress(false);
    getTMSLoad(dataOrderRunningg,CenterUser,selectedPageSize,selectedPageId,filterr)
    setLoads();
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      if (selectedRowKeys.length > 0) {
        setSelectedShipment(selectedRows);
      } else {
        setSelectedShipment([]);
      }
    },
  };

  return (
    <Col className="children-inner-container-with-bg">
      <Row>
        <Col span={6}>
          <div className="titulo-container">
            <Button
              className="back-icon"
              type="primary"
              shape="circle"
              icon={<ArrowLeftOutlined />}
              onClick={() => setSelecteLoad()}
            />

            <h1 className="titulo2">Detalle de la Carga</h1>
          </div>
          <h4 className="subtitulo">{selecteLoad.LoadId}</h4>

          <TextField
            className="custom-filter2"
            label="Buscar"
            onChange={(value) => {
              setFilter(value);
            }}
          />
        </Col>
        <Col span={12} offset={12} className="children-inner-col">
          <Button
            type="primary"
            shape="round"
            icon={<CloudUploadOutlined />}
            size={"large"}
            className="sync-button"
            disabled={selectedShipment.length === 0}
            onClick={() => {
              handleSave();
            }}
          >
            Sincronizar
          </Button>
        </Col>
      </Row>
      <Row className="children-table3-container">
        <Table
          className="children-table3"
          columns={columns}
          dataSource={tmsshipment}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
            selections: [
              Table.SELECTION_NONE,
              Table.SELECTION_ALL,
              Table.SELECTION_INVERT,
            ],
          }}
          sle
          size="small"
          rowKey="key"
          onRow={(record) => {
            return {
              onClick: () => {},
            };
          }}
        />
      </Row>
      {visibleDrawerLines === true ? (
        <div className="children-inner-container-with-bg">
          <Drawer
            key="drawer"
            title={"Lineas de carga"}
            width={700}
            closable={true}
            onClose={() => {
              setVisibleDrawerLines(false);
            }}
            visible={visibleDrawerLines}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div style={{ textAlign: "right" }}>
                <Button
                  type="ghost"
                  shape="round"
                  onClick={() => {
                    setVisibleDrawerLines(false);
                  }}
                >
                  Cerrar
                </Button>
              </div>
            }
          >
            <Table
              className="children-table2"
              columns={columnsLines}
              dataSource={tmslines}
              sle
              size="small"
              key="table03"
              rowKey="key"
            />
          </Drawer>
        </div>
      ) : (
        <Row></Row>
      )}
      {visibleDrawerContainers === true ? (
        <div className="children-inner-container-with-bg">
          <Drawer
            key="drawer2"
            title={"Contenedores"}
            width={700}
            closable={true}
            onClose={() => {
              setVisibleDrawerContainers(false);
            }}
            visible={visibleDrawerContainers}
            bodyStyle={{ paddingBottom: 80 }}
            footer={
              <div style={{ textAlign: "right" }}>
                <Button
                  type="ghost"
                  shape="round"
                  onClick={() => {
                    setVisibleDrawerContainers(false);
                  }}
                >
                  Cerrar
                </Button>
              </div>
            }
          >
            <Table
              className="children-table2"
              columns={columnsContainers}
              dataSource={tmsContainers}
              sle
              size="small"
              key="table04"
              rowKey="key"
            />
          </Drawer>
        </div>
      ) : (
        <Row></Row>
      )}
      {syncInProgress && (
        <Spin tip="Sincronizando" size="large">
          <div className="content" />
        </Spin>
      )}
    </Col>
  );
};
