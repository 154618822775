import React, { useEffect, useState } from "react";
import {
  Button,
  Drawer,
  Card,
  Divider,
  Form,
  Input,
  Select
} from "antd";
import TextField from "@mui/material/TextField";
import { useMutation } from "@apollo/client";
import Swal from "sweetalert2";
import {
  insertHistoricalOrdersTable,
  insertDeleteOrdersTable,
  insertDeleteOrdersLine,
  deleteOrdersTableInUseToLeft,
} from "../../queries/schedulingQueries";

import localStorageService from "../../../../services/localStorageService";
import { useScheduling } from '../../context/SchedulingContext';
const userId = localStorageService.get("id");

export const DeleteOrders = (props) => {
  const [istHistoricalOrdersTable] = useMutation(insertHistoricalOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [istDeleteOrdersTable] = useMutation(insertDeleteOrdersTable, {
    fetchPolicy: "no-cache",
  });
  const [istDeleteOrdersLine] = useMutation(insertDeleteOrdersLine, {
    fetchPolicy: "no-cache",
  });
  const [dteOrdersTableInUseToLeft] = useMutation(
    deleteOrdersTableInUseToLeft,
    { fetchPolicy: "no-cache" }
  );
  const {
    queryDataOrders, selectedWarehouse
} = useScheduling();
  const { showDrawer, setShowDrawer, Order} = props;
  const [form] = Form.useForm();
  const formItemLayout = {
    labelCol: {
      lg: { span: 8 },
    },
    wrapperCol: {
      lg: { span: 15 },
    },
  };
  const handleClear = () => {
    setShowDrawer(false);
    form.setFieldsValue({
      name: "",
      type: "",
      coment: "",
      identificationNumber: "",
    });
  };
  const handleSave = async (Orders) => {
    const OrderNumber = Orders[0].orderNumber;
    if (
      !form.getFieldValue("name") ||
      !form.getFieldValue("identificationNumber") ||
      !form.getFieldValue("type") ||
      !form.getFieldValue("coment")
    ) {
      Swal.fire({
        title: "Campos vacios",
        text: `Hace falta diligenciar campos, por favor diligencielos antes de dar click en guardar`,
        icon: "error",
        confirmButtonText: "Cerrar",
      });
    } else {
      form
        .validateFields()
        .then(async (_) => {
          const values = form.getFieldsValue();
          const { name, identificationNumber, type, coment } = values;
          const instHistoricalOrdersTable = await istHistoricalOrdersTable({
            variables: {
              name,
              identificationNumber,
              type,
              coment,
              userId: userId,
              ordenNumber: Order[0].orderNumber,
            },
            fetchPolicy: "no-cache",
          });
          if (instHistoricalOrdersTable.data.insert_historicalDeleteOrders.affected_rows > 0) {
            const instDeleteOrdersTable = await istDeleteOrdersTable({
              variables: {
                orderNumber: Order[0]?.orderNumber,
                address: Order[0]?.address,
                addressComplement: Order[0]?.addressComplement,
                color: Order[0]?.color,
                consecutiveBill: Order[0]?.consecutiveBill,
                consecutiveBurden: Order[0]?.consecutiveBurden,
                consecutiveRemission: Order[0]?.consecutiveRemission,
                consecutiveSaleOrder: Order[0]?.consecutiveSaleOrder,
                custEmail: Order[0]?.custEmail,
                custFullName: Order[0]?.custFullName,
                custIdentificationNumber: Order[0]?.custIdentificationNumber,
                custPhoneNumber: Order[0]?.custPhoneNumber,
                destination: Order[0]?.destination,
                distributionCenterid: Order[0]?.distributionCenterid,
                enableDeliveryWindow: Order[0]?.enableDeliveryWindow,
                priority: Order[0]?.priority,
                route: Order[0]?.routeComplet === null ? null : Order[0]?.routeComplet[0]?.id,
                sectorId: Order[0]?.sectorId,
                totalCubicMeters: Order[0]?.totalCubicMeters,
                type: Order[0]?.type,
                weight: Order[0]?.weight,
                paymentMethod: Order[0]?.paymentMethod,
                consecutiveShipping: Order[0]?.consecutiveShipping
              },
              fetchPolicy: "no-cache",
            });

            if (instDeleteOrdersTable.data.insert_deleteOrdersTable.affected_rows > 0) {

              if (Order[0].ordersLines.length > 0) {
                let dataInsertDeleteLines = []
                for (let i = 0; i < Order[0].ordersLines.length; i++) {
                  const dataLines = Order[0].ordersLines[i];

                  const dataInsert = {
                    Invoice: dataLines.Invoice,
                    PackingSlip: dataLines.PackingSlip,
                    cubicMeters: dataLines.cubicMeters,
                    deliveredQuantity: dataLines.deliveredQuantity,
                    depth: dataLines.depth,
                    height: dataLines.height,
                    externalId: dataLines.externalId,
                    externalInvoiceId: dataLines.externalInvoiceId,
                    externalSalesId: dataLines.externalSalesId,
                    initialQuantity: dataLines.initialQuantity,
                    numberLine: dataLines.numberLine,
                    orderNumber: dataLines.orderNumber,
                    orderedQuantity: dataLines.orderedQuantity,
                    packageId: dataLines.packageId,
                    partWeight: dataLines.partWeight,
                    partsPerBox: dataLines.partsPerBox,
                    processed: dataLines.processed,
                    productName: dataLines.productName,
                    productNumber: dataLines.productNumber,
                    safetyFactor: dataLines.safetyFactor,
                    summationQuantity: dataLines.summationQuantity,
                    totalCubicMeters: dataLines.totalCubicMeters,
                    weight: dataLines.weight,
                    width: dataLines.width
                  }
                  dataInsertDeleteLines = dataInsertDeleteLines.concat(dataInsert)
                }

                const resDeleteOrdersTable = await istDeleteOrdersLine({
                  variables: {
                    objects: dataInsertDeleteLines,
                  },
                  fetchPolicy: "no-cache",
                });

              }
              const resDelete = await dteOrdersTableInUseToLeft({
                variables: {
                  id: Order[0].id,
                },
                fetchPolicy: "no-cache",
              });
              console.log(resDelete);
            }
            handleClear();
            Swal.fire({
              title: `Liberación de Orden Numero ${OrderNumber} Exitosa`,
              text: `Se libero la orden`,
              icon: "success",
              confirmButtonText: "Cerrar",
            });
            queryDataOrders(selectedWarehouse);
          }
          
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    <div
      style={{
        margin: "10px",
      }}
    >
      <Drawer
        key="drawer"
        title={`Liberar Orden`}
        width={600}
        onClose={() => {
          handleClear();
          setShowDrawer(false);
        }}
        visible={showDrawer}
        bodyStyle={{ paddingBottom: 80 }}
        footer={
          <div style={{ textAlign: "right" }}>
            <Button
              type="primary"
              shape="round"

              onClick={() => {
                handleSave(Order);
              }}
            >
              Guardar
            </Button>
            <Divider type="vertical" />
            <Button
              type="ghost"
              shape="round"
              onClick={() => {
                handleClear();
                setShowDrawer(false);
              }}
            >
              Cerrar
            </Button>
          </div>
        }
      >
        <Card
          key="card"
          title={`Numero de Ordern a Liberar ${Order[0].orderNumber}`}
          style={{ marginBottom: "10px", minHeight: "0%" }}
          size="small"
        >
          <Form
            {...formItemLayout}
            key="form"
            form={form}
            name="configuration-users"
            onClose={() => {
              handleClear();
              setShowDrawer(false);
            }}
          >
            <Form.Item name="id" hidden>
              <Input />
            </Form.Item>
            <Form.Item
              name="name"
              labelAlign="left"
              label="Nombre y apellido"
              hasFeedback
            >
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              name="identificationNumber"
              labelAlign="left"
              label="Numero de Documento"
              hasFeedback
            >
              <TextField className="textField" />
            </Form.Item>
            <Form.Item
              labelAlign="left"
              hasFeedback
              name="type"
              label="Motivo de Liberación"
            >
              <Select
                placeholder="Seleccione..."
                allowClear
                optionFilterProp="children"
                mode="singuler"
                style={{ width: "100%" }}
                onChange={(value, object) => { }}
              >
                return (
                <Select.Option value={"Devuelto"}>
                  Devuelto
                </Select.Option>
                <Select.Option value={"Completamente Entregado"}>
                  Completamente Entregado
                </Select.Option>
                <Select.Option value={"Interno UM"}>
                  Interno UM
                </Select.Option>
                );
              </Select>
            </Form.Item>
            <Form.Item
              name="coment"
              labelAlign="left"
              label="Justificación de Liberación"
              hasFeedback
            >
              <TextField className="textField" />
            </Form.Item>
          </Form>
        </Card>
      </Drawer>
    </div>
  );
};
